@import "assets/styles/mixins";
@import "assets/styles/variables";

.outerCheckoutContainer {
  .gkit-green {
    color: $green;
  }

  .gkit-charcoal {
    color: $charcoal;
  }

  .gkit-clickable {
    cursor: pointer;
  }

  .gkit-medium {
    font-weight: 500;
  }
}

@include media-breakpoint-down(sm) {
  .subscriptionSummaryContainer {
    .gkit-input-label {
      font-size: 0.9375rem;
    }
  }

  .outerCheckoutContainer {
    .gkit-mobile-17 {
      font-size: 1.0625rem;
      letter-spacing: -0.01em;
      line-height: 1.4em;
    }

    .gkit-mobile-15 {
      font-size: 0.9375rem;
      line-height: 1.4em;
    }

    .gkit-mobile-13 {
      font-size: 0.8125rem;
      line-height: 1.4em;
    }
  }
}

html,
body,
#root {
  min-height: 100vh;
}
