@import "../../../assets/styles/variables";
.container {
  background-color: white;
  border: 1px solid $gray-2;
  border-radius: 4px;
}

.rotatedCaret {
  transform: rotate(180deg);
}
.rowContainer {
  margin: -4px;
}

.tinBlockContainer {
  margin: 4px;
}

.imageContainer {
  background-color: rgba(245, 218, 223, 0.3);
  border-radius: 5px;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.toggle {
  background-color: white;
  border: 1px solid $charcoal !important;
  border-radius: 4px;

  font-size: 11px;
  font-family: "ApercuPro";
  font-weight: normal !important;
  color: $charcoal;
  padding: 2px 6px !important;

  &.active {
    background-color: $charcoal !important;
    color: white;
  }
}
