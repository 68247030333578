.btn-icon {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:focus {
    outline: none;
  }

  &.gkit-btn-xs {
    width: 30px;
  }

  &.gkit-btn-sm {
    width: 36px;
  }
}
