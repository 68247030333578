@import "../../../assets/styles/variables";

.lineItemBadge {
  border-radius: 100px;
  padding: 6px 8px;
}

.centreAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otpInner {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  color: $charcoal;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpOuter {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  display: inline-block;
  border-radius: 1px;
  border-radius: 50%;
  position: relative;
}
