/**
 * Fonts.
 * All on the same font family so weights aren't applied by the browser.
 */
@font-face {
  font-family: "ApercuPro";
  font-weight: normal;
  src: url("../../../fonts/apercu/apercu_regular_pro.otf");
}

@font-face {
  font-family: "ApercuPro";
  font-weight: bold;
  src: url("../../../fonts/apercu/apercu_bold_pro.otf");
}

@font-face {
  font-family: "ApercuPro";
  font-weight: 500;
  src: url("../../../fonts/apercu/apercu_medium_pro.otf");
}

@font-face {
  font-family: "ApercuProMono";
  font-weight: normal;
  src: url("../../../fonts/apercu/apercu_mono_pro.otf");
}
