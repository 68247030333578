.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
