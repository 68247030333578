@import "../../assets/styles/source/imports/variables";

.form-group.input-group {
  .gkit-input-md + .input-group-append {
    border: 1px solid $gray-1;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .gkit-input-md.is-invalid + .input-group-append {
    border-color: $red;
  }
}

.form-group.input-group > .form-control-appended {
  border-right: 0px;
}
