.container {
    background-color: white;
    
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 3;
    // This is rogue and arbitrary... But it seems to work for every case.
    padding-top: 200px;
}