@import "../../assets/styles/variables";

.container {
  background: $charcoal;
  border-radius: 6px;
  display: flex;
  overflow: hidden;
}

.content {
  padding: 1rem;

  @media (min-width: $screen-md) {
    padding-right: 0;
  }
}

.img {
  max-width: 200px;
  padding: 2rem 2rem 0 2rem;
  margin-bottom: -5rem;
  align-self: flex-start;
  display: none;

  @media (min-width: $screen-md) {
    display: block;
  }
}

.title.title,
.copy.copy,
.terms.terms {
  color: $white;
}

.standout {
  font-size: 1.2em;
  font-weight: bold;
  color: $green;
}
