@import "../../assets/styles/variables";

.mode-backdrop {
  position: fixed;
  background-color: $charcoal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  outline: 0;
}

.mode {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  overflow: hidden;
  outline: 0;
  pointer-events: none;

  .mode-dialog {
    position: relative;
    z-index: 1050;
    width: 100%;
    margin-top: 70px;
    height: calc(100% - 70px);
    background-color: white;
    pointer-events: all;
    border-radius: 1rem 1rem 0 0;
  }

  .mode-content {
    position: relative;
    padding: 1.5rem;
    height: 100%;
    overflow-y: auto;
    .button-container {
      z-index: 1050;
      position: absolute;
      top: 1rem;
      right: 1rem;
      button {
        padding: 0;
      }
    }
  }
}

.modal-normal {
  &-appear {
    .mode-backdrop {
      opacity: 0;
    }
    .mode-dialog {
      // opacity: 0;
      top: 100%;
    }
  }
  &-appear-done {
    .mode-backdrop {
      opacity: 0.8;
      transition: opacity 0.15s linear;
    }
    .mode-dialog {
      top: 0%;
      transition: top 0.3s linear;
      transition-delay: 0.15s;
    }
  }
  &-enter {
    .mode-backdrop {
      opacity: 0;
    }
    .mode-dialog {
      top: 100%;
    }
  }

  &-enter-done {
    .mode-backdrop {
      opacity: 0.8;
      transition: opacity 0.15s linear;
    }
    .mode-dialog {
      top: 0%;
      transition: top 0.3s ease-in-out;
      transition-delay: 0.15s;
    }
  }
  &-exit {
    .mode-backdrop {
      opacity: 0.8;
    }
    .mode-dialog {
      top: 0%;
    }
  }
  &-exit-active {
    .mode-backdrop {
      opacity: 0;
      transition: opacity 0.15s linear;
      transition-delay: 0.15s;
    }
    .mode-dialog {
      top: 100%;
      transition: top 0.3s linear;
    }
  }
}

@media (min-width: $screen-xs) {
  .mode {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .mode-dialog {
      width: calc(100% - 2rem);
      max-width: 550px;
      margin: auto;
      height: initial;
      border-radius: initial;
    }
  }
}

// globally set scrollbars to not show ^__^
*::-webkit-scrollbar {
  display: none;
}
