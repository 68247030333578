@import "../../../assets/styles/variables";

.inputContainer {
  position: relative;

  .inputError {
    border-color: $red;
    color: $red;
  }

  .inputSuccess,
  .inputSuccess:disabled {
    background-color: white;
    border-color: $green;
    color: $green;
  }

  > img {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.discountInfoContainer {
  background-color: $gray-400;
  padding: 0.75rem;
}
