@import "../../assets/styles/variables";

.gkit-stripe-input {
  height: 40px;
  padding: 9px 12px;
  background-color: white;

  &.is-invalid {
    border: 1px solid $red;
  }
}
