@import "assets/styles/variables";

#enquire_labs_target {
  font-family: "ApercuPro", sans-serif !important;
  -webkit-font-smoothing: antialiased;

  .notice.notice--error {
    color: $red;
    margin: 0 0 0.5rem !important;

    padding: 0.5rem;
    border-radius: 2px;
    border: 1px solid $red;
  }
  .enquire__survey {
    display: block;

    .enquire-survey__title {
      font-size: 1.2rem;
    }

    .os-step__description {
      padding-left: 0.5rem;

      .field__message--error {
        color: $red;
      }

      &.enquire__survey-actions {
        padding-left: 0;

        .btn {
          background-color: $charcoal;
          color: white;
          font-weight: 700;

          margin-top: 1rem;
        }
      }

      .field__input.enquire__other-response {
        padding-top: 0.4rem !important;
        padding-bottom: 0.4rem !important;
      }
    }

    .enquire__branding {
      display: none;
    }
  }
}
