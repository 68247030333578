@import "../../../../../assets/styles/variables";

.codeContainer {
  display: flex;
  flex-direction: row;
  margin: 0;
  width: 100%;
  max-width: 300px;
  justify-content: center;

  input {
    text-align: center;
    flex: 1 1;
    width: 10%;
  }
  input:first-child {
    margin-left: 0 !important;
  }
}

.isInvalid {
  border-color: $red !important;
}

@media (min-width: $screen-sm) {
  .codeContainer {
    margin: 0 auto;
  }
}
