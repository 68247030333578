@import "../styles/source/imports/variables";

$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$gray-100: $gray-1;
$gray-150: $gray-1-5;
$gray-200: $gray-2;
$gray-300: $gray-3;
$gray-400: $gray-4;

:export {
  gray: $gray-0;
  gray1: $gray-1;
  gray15: $gray-1-5;
  gray2: $gray-2;
  gray3: $gray-3;
  charcoal: $charcoal;
  lightenCharcoal: lighten($charcoal, 50%);
  pink: $pink;
  green: $green;
  red: $red;
  white: $white;
}
