@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.container {
  .imgContainer {
    border-radius: 4px;
    height: 120px;
    width: 120px;

    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    margin-right: 16px;

    @include media-breakpoint-down(sm) {
      width: 64px;
      height: 64px;
    }

    img {
      width: 100%;
    }

    .quantityContainer {
      height: 24px;
      min-width: 24px;
      background-color: $red;
      border-radius: 12px;

      padding-left: 6px;
      padding-right: 6px;

      position: absolute;
      right: 8px;
      bottom: 8px;

      .quantityText {
        color: white;
        text-align: center;
        line-height: 24px;
      }

      @include media-breakpoint-down(sm) {
        right: 4px;
        bottom: 4px;
      }
    }
  }

  .badgeContainer {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
