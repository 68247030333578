.container {
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 3;

  padding-top: 200px;
}
