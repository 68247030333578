@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.container {
  margin-top: 20px;

  @include media-breakpoint-down(sm) {
    margin-top: 8px;
  }
}
