@import "imports/fonts";
@import "imports/variables";
@import "imports/base";
@import "imports/animations";

/**
* Charcoal: $charcoal
* Grey 0: #706C69
* Grey 1: #959291
* Grey 2: $gray-200
* Grey 3: $gray-300
*/

body {
  font-size: 16px;
}

.gkit-h {
  &0,
  &1,
  &2,
  &3 {
    @include gkit-text();
    @include gkit-title();
  }

  &0 {
    font-size: 4rem;
    line-height: 1em;
    letter-spacing: -0.03em;
  }

  &1 {
    font-size: 3rem;
    line-height: 1.2em;
    letter-spacing: -0.02em;
  }

  &2 {
    font-size: 1.75rem;
    line-height: 1.14em;
    letter-spacing: -0.01em;
  }

  &3 {
    font-size: 1.375rem;
    letter-spacing: -0.01em;
    line-height: 1.27em;
  }
}

.gkit-p {
  &-22,
  &-20,
  &-17,
  &-15,
  &-13,
  &-11 {
    @include gkit-text();
    @include gkit-body();
  }

  &-22 {
    font-size: 1.375rem;
    letter-spacing: -0.01em;
    line-height: 1.25em;

    li {
      padding-top: 0.25rem;
      padding-bottom: 0.25em;
    }
  }

  &-20 {
    font-size: 1.25rem;
    letter-spacing: -0.01em;
    line-height: 1.25em;

    li {
      padding-top: 0.25rem;
      padding-bottom: 0.25em;
    }
  }

  &-17 {
    font-size: 1.0625rem;
    letter-spacing: -0.01em;
    line-height: 1.4em;

    li {
      padding-top: 0.25rem;
      padding-bottom: 0.25em;
    }
  }

  &-15 {
    font-size: 0.9375rem;
    line-height: 1.4em;

    li {
      padding-top: 0.125rem;
      padding-bottom: 0.125em;
    }
  }

  &-13 {
    font-size: 0.8125rem;
    line-height: 1.4em;

    li {
      padding-top: 0.125rem;
      padding-bottom: 0.125em;
    }
  }

  &-11 {
    font-size: 0.6875rem;
    line-height: 1.4em;

    li {
      padding-top: 0.125rem;
      padding-bottom: 0.125em;
    }
  }
}

.gkit-blockquote {
  @include gkit-text();

  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: -0.02em;
  color: $charcoal;
}

.gkit-uppercase {
  @include gkit-text();
  text-transform: uppercase;
}

.gkit-bold {
  font-weight: bold;
}

.gkit-normal {
  font-weight: normal;
}

.gkit-light {
  color: $gray-0 !important;

  &-1 {
    color: $gray-1 !important;
  }
  &-1-5 {
    color: $gray-1-5 !important;
  }
  &-2 {
    color: $gray-2 !important;
  }
  &-3 {
    color: $gray-3 !important;
  }
}

.gkit-bg {
  &-dark {
    background: $charcoal !important;
  }
  &-green {
    background: $green !important;
  }
  &-palegreen {
    background: $palegreen !important;
  }
  &-pink {
    background: $pink !important;
  }
  &-gray-4 {
    background: $gray-4 !important;
  }
}

.gkit-btn {
  &-lg {
    @include gkit-btn(2px, 1.0625rem, 2.375rem, 0.75rem);
  }

  &-md {
    @include gkit-btn(2px, 0.9375rem, 1.5rem, 0.5rem);
  }

  &-sm {
    @include gkit-btn(1px, 0.8125rem, 1.5rem, 0.38rem);
  }

  &-xs {
    @include gkit-btn(1px, 0.8125rem, 0.625rem, 0.15rem);
  }
}

div.gkit-loading {
  position: relative;
  &::after {
    @include is-loading($charcoal, 3px);
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  li {
    color: transparent !important;
  }
}

.gkit-toggle {
  &-md {
    @include gkit-toggle();

    line-height: calc(2.625rem - 2px);
    height: 2.625rem;
  }
}

[class*="gkit-btn"] {
  &.gkit-primary {
    background-color: $charcoal;
    color: white;

    &:hover {
      color: white;
    }
    &.gkit-loading::after {
      @include is-loading(white);
    }
    &.gkit-btn {
      &-lg.gkit-loading::after {
        @include is-loading(white, 3px);
      }
      &-md.gkit-loading::after {
        @include is-loading(white, 2px);
      }
      &-sm.gkit-loading::after {
        @include is-loading(white, 2px);
      }
      &-xs.gkit-loading::after {
        @include is-loading(white, 2px);
      }
    }
  }

  &.gkit-secondary {
    background-color: transparent;
    border-color: $charcoal;
    border-width: 2px;
    border-style: solid;
    color: $charcoal;

    &.gkit-white {
      border-color: white;
      color: white;

      &:hover {
        color: white;
      }
    }

    &:not(.gkit-white):hover {
      color: $charcoal;
    }
    &.gkit-loading::after {
      @include is-loading($charcoal);
    }
    &.gkit-btn {
      &-lg.gkit-loading::after {
        @include is-loading($charcoal, 3px);
      }
      &-md.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
      &-sm.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
      &-xs.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
    }
  }

  &.gkit-tertiary {
    background-color: $gray-3;
    color: $charcoal;

    &:hover {
      color: $charcoal;
    }

    &.gkit-loading::after {
      @include is-loading($charcoal);
    }

    &.gkit-btn {
      &-lg.gkit-loading::after {
        @include is-loading($charcoal, 3px);
      }
      &-md.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
      &-sm.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
      &-xs.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
    }
  }

  &.gkit-quaternary {
    background-color: $white;
    color: $charcoal;

    &:hover {
      color: $charcoal;
    }

    &.gkit-loading::after {
      @include is-loading($charcoal);
    }

    &.gkit-btn {
      &-lg.gkit-loading::after {
        @include is-loading($charcoal, 3px);
      }
      &-md.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
      &-sm.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
      &-xs.gkit-loading::after {
        @include is-loading($charcoal, 2px);
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: $gray-2;
    color: white;
    cursor: not-allowed;

    &:hover {
      color: white;
    }
    &.gkit-loading::after {
      @include is-loading(white);
    }
    &.gkit-btn {
      &-lg.gkit-loading::after {
        @include is-loading(white, 3px);
      }
      &-md.gkit-loading::after {
        @include is-loading(white, 2px);
      }
      &-sm.gkit-loading::after {
        @include is-loading(white, 2px);
      }
      &-xs.gkit-loading::after {
        @include is-loading(white, 2px);
      }
    }
  }
}

.gkit-select,
input.gkit-select {
  &-lg {
    @include gkit-select();

    font-size: 1.0625rem;
    height: 3.125rem;
    padding-left: 1.25rem;
    padding-right: calc(1.25rem + 26px);
    background-position: right 1.25rem center;
  }

  &-md {
    @include gkit-select();

    font-size: 0.9375rem;
    height: 2.5rem;
    padding-left: 1rem;
    padding-right: calc(1rem + 26px);
    background-position: right 1rem center;
  }
}

.gkit-input {
  &-lg {
    @include gkit-input();

    height: 3.125rem;
    line-height: calc(3.125rem - 2px);
  }

  &-md {
    @include gkit-input();

    height: 2.5rem;
    line-height: calc(2.5rem - 2px);
  }

  &-label {
    @include gkit-text();

    font-weight: bold;
    font-size: 1.0625rem;
    color: #181d38;

    line-height: 1.3em;
    margin-bottom: 5px;
  }
}

textarea.gkit-input {
  @include gkit-input();

  line-height: 1.3em;
  min-height: 100px;
}

.gkit-legacy-show {
  display: none !important;
}

.gkit-round-text {
  &-md {
    @include gkit-round-text();
    width: 37px;
    height: 37px;
  }

  &-sm {
    @include gkit-round-text();
    width: 27px;
    height: 27px;
  }
}

.gkit-card {
  border-radius: 0;
  background-color: $gray-5;
  border-color: $gray-1-5;
}

.gkit-card-header {
  background: white;
}

.cursor-pointer {
  cursor: pointer;
}

.clear-button {
  background: none;
  border: none;
}

.custom-control.custom-radio {
  & .custom-control-input ~ .custom-control-label::before {
    background-color: $gray-4;
    border: 1px solid $gray-1;
  }

  & .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $charcoal;
    box-shadow: none;
  }

  & .custom-control-input:checked ~ .custom-control-label::after {
    top: 0;
    left: -1.75rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
