@import "../../assets/styles/variables";

.container {
  background-color: $gray-400;
  padding: 16px;

  .g0 {
    color: $gray-0;
  }
}
