@import "../../../assets/styles/variables";

.container {
  padding-left: 16px;
  padding-right: 16px;

  background-color: $gray-400;

  .headerContainer {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price {
      font-family: "ApercuProMono", sans-serif !important;

      .strike {
        text-decoration: line-through;
        color: $gray-100;
        margin-right: 8px;
      }
    }

    .chevron {
      margin-left: 8px;
      width: 13px;

      -moz-transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear;

      &.expanded {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .contentContainer {
    padding-bottom: 16px;
  }
}
