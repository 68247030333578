$gray-0: #706c69;
$gray-1: #959291;
$gray-1-5: #c9c4c1;
$gray-2: #e2dbd5;
$gray-3: #f2efee;
$gray-4: #f8f7f7;
$gray-5: #fdfcfc;
$pink: #f5dadf;
$green: #52b57a;
$palegreen: #e7f1eb;
$red: #e2255e;
$cream: #fef8f4;
$white: #ffffff;

$charcoal: #2d2926;
$charcoal-shadow: lighten($charcoal, 50%);
