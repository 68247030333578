@import "../../../../assets/styles/variables";

#password-login {
  #submit-button {
    display: block;
  }
}

@media (min-width: $screen-xs) {
  #submit-button {
    width: 100%;
  }
}
