@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.container {
  background-color: $gray-400;

  margin-top: 16px;
  padding: 16px;

  .paymentButtons {
    z-index: 1;
    position: relative;
  }
  
  @include media-breakpoint-up(md) {
    .paymentButtons {
      max-width: 40%;
      flex-grow: 1;
    }
  }
}
