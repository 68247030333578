@import "../../assets/styles/variables";

.container {
  background-color: white;
  padding-left: 24px;
  height: 70px;
  border-bottom: 1px solid $gray-300;

  .img {
    height: 20px;
  }
}
