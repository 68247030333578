@import "../../assets/styles/variables";

.custom-control {
  z-index: initial !important;
}

.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-color: $gray-4;
  border: 1px solid $gray-1;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $charcoal;
  box-shadow: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  top: 0;
  left: -0.25rem;
  width: 1.5rem;
  height: 1.5rem;
}

.form-control:focus {
  border-color: $charcoal;
  box-shadow: 0 0 0 0.2rem lighten($charcoal, 50%);
}

#prepend-control.control {
  position: relative;
  .prepend-image {
    position: absolute;
    top: 6px;
    left: 6px;
  }
  .input-with-image {
    padding-left: 60px;
  }
}

// Remove inner arrows for number type inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
